

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__logo {
        margin: 20px 0px;
    }
    &__name {
        letter-spacing: 6px;
        color: #999999;
        text-align: center;
        font-size: 11px;
        line-height: 12px;
        font-family: pt-sans-narrow, sans-serif;
    }
}

.bottom {
    position: absolute;
    bottom: 50px;
    right: 50px;
    left: 50px;
    display: flex;
    justify-content: space-between;

    &__left {
        display: flex;
        flex-direction: column;
        letter-spacing: 1px;
        color: #999999;
        font-size: 13px;
        line-height: 15px;
        font-family: pt-sans-narrow, sans-serif;
    }
    &__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}